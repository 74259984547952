.testimonials-section {
    padding: 80px 20px;
    background: rgba(0, 0, 0, 0.8);
    min-height: 100vh;
    scroll-margin-top: 80px;
}

.testimonials-container {
    max-width: 1200px;
    margin: 0 auto;
}

.testimonials-slider {
    position: relative;
    margin-top: 50px;
    height: 400px;
}

.testimonial-card {
    position: absolute;
    width: 100%;
    opacity: 0;
    transition: all 0.5s ease;
    transform: translateX(100%);
}

.testimonial-card.active {
    opacity: 1;
    transform: translateX(0);
}

.testimonial-content {
    background: rgba(7, 24, 34, 0.6);
    border-radius: 15px;
    padding: 40px;
    display: flex;
    gap: 40px;
    align-items: center;
    border: 1px solid rgba(0, 255, 255, 0.1);
}

.testimonial-image {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    overflow: hidden;
    border: 3px solid #00ffff;
}

.testimonial-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.testimonial-text {
    flex: 1;
}

.testimonial-text > p {
    font-size: 1.2rem;
    line-height: 1.8;
    color: rgba(255, 255, 255, 0.9);
    margin-bottom: 20px;
    font-style: italic;
}

.testimonial-author h4 {
    color: #00ffff;
    font-size: 1.4rem;
    margin-bottom: 5px;
}

.testimonial-author p {
    color: rgba(255, 255, 255, 0.7);
    font-size: 1.1rem;
}

.rating {
    margin-top: 15px;
}

.star {
    color: #00ffff;
    font-size: 1.5rem;
    margin-right: 5px;
}

.slider-dots {
    position: absolute;
    bottom: -40px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    gap: 10px;
}

.dot {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.3);
    border: none;
    cursor: pointer;
    transition: all 0.3s ease;
}

.dot.active {
    background: #00ffff;
    transform: scale(1.2);
}

.slider-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: rgba(0, 255, 255, 0.1);
    border: 1px solid rgba(0, 255, 255, 0.3);
    color: #00ffff;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    cursor: pointer;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease;
}

.slider-button:hover {
    background: rgba(0, 255, 255, 0.2);
    transform: translateY(-50%) scale(1.1);
}

.slider-button.prev {
    left: -70px;
}

.slider-button.next {
    right: -70px;
}

.testimonial-content {
    padding: 60px;
    text-align: center;
}

.testimonial-text {
    max-width: 800px;
    margin: 0 auto;
}

.testimonial-text > p {
    font-size: 1.3rem;
    line-height: 1.8;
    margin-bottom: 30px;
}

@media screen and (max-width: 1024px) {
    .slider-button {
        width: 40px;
        height: 40px;
    }

    .slider-button.prev {
        left: -50px;
    }

    .slider-button.next {
        right: -50px;
    }
}

@media screen and (max-width: 768px) {
    .slider-button {
        display: none;
    }

    .testimonial-content {
        padding: 30px;
    }

    .testimonial-text > p {
        font-size: 1.1rem;
    }

    .testimonial-image {
        width: 150px;
        height: 150px;
    }

    .testimonials-slider {
        height: 600px;
    }
} 