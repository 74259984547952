.navbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(10px);
    padding: 15px 0;
    z-index: 1000;
    border-bottom: 1px solid rgba(0, 255, 255, 0.1);
    box-shadow: 0 0 20px rgba(0, 255, 255, 0.1);
}

.navbar-container {
    max-width: 1400px;
    margin: 0 auto;
    padding: 0 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.logo-container {
    flex: 0 0 auto;
    padding: 20px;
    background: rgba(255, 255, 255, 0.15);
    border-radius: 12px;
    border: 2px solid rgba(0, 255, 255, 0.3);
    transition: all 0.3s ease;
    margin-right: 20px;
}

.logo-container:hover {
    background: rgba(255, 255, 255, 0.2);
    border-color: rgba(0, 255, 255, 0.6);
    box-shadow: 0 0 25px rgba(0, 255, 255, 0.4);
}

.company-logo {
    height: 120px;
    width: auto;
    transition: all 0.3s ease;
    cursor: pointer;
    filter: brightness(1.2) contrast(1.2);
}

.nav-items {
    flex: 1;
    display: flex;
    justify-content: center;
}

.nav-links {
    display: flex;
    list-style: none;
    gap: 30px;
    margin: 0;
    padding: 0;
    align-items: center;
}

.nav-links li a {
    color: white;
    text-decoration: none;
    font-size: 1rem;
    transition: all 0.3s ease;
    padding: 8px 12px;
}

.nav-links li a:hover {
    color: #00ffff;
    text-shadow: 0 0 10px rgba(0, 255, 255, 0.5);
}

.company-name {
    display: flex;
    flex-direction: column;
    align-items: center;
    line-height: 1;
    margin: 0 20px;
}

.neoplus {
    font-size: 1.4rem;
    font-weight: bold;
    animation: colorChange 8s infinite;
}

.software {
    font-size: 1.2rem;
    font-weight: bold;
    animation: colorChange 8s infinite;
    animation-delay: 2s;
}

@keyframes colorChange {
    0% { 
        color: #00ffff;
        text-shadow: 0 0 10px rgba(0, 255, 255, 0.7);
    }
    25% { 
        color: #ff00ff;
        text-shadow: 0 0 10px rgba(255, 0, 255, 0.7);
    }
    50% { 
        color: #00ff00;
        text-shadow: 0 0 10px rgba(0, 255, 0, 0.7);
    }
    75% { 
        color: #ff0000;
        text-shadow: 0 0 10px rgba(255, 0, 0, 0.7);
    }
    100% { 
        color: #00ffff;
        text-shadow: 0 0 10px rgba(0, 255, 255, 0.7);
    }
}

.language-selector {
    display: flex;
    gap: 10px;
}

.language-button {
    background: none;
    border: 1px solid rgba(0, 255, 255, 0.3);
    color: white;
    padding: 8px 16px;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease;
}

.language-button:hover,
.language-button.active {
    background: rgba(0, 255, 255, 0.1);
    border-color: #00ffff;
}

/* Mobil menü butonu stilleri */
.mobile-menu-button {
    display: none;
    flex-direction: column;
    justify-content: space-between;
    width: 30px;
    height: 24px;
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1001;
}

.mobile-menu-button span {
    width: 100%;
    height: 3px;
    background: #00ffff;
    border-radius: 3px;
    box-shadow: 0 0 10px rgba(0, 255, 255, 0.5);
}

/* Mobil menü stilleri */
.mobile-menu {
    position: fixed;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.95);
    backdrop-filter: blur(10px);
    z-index: 1000;
    transition: all 0.3s ease;
    padding: 80px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.mobile-menu.active {
    left: 0;
}

.mobile-menu ul {
    list-style: none;
    padding: 0;
    margin: 0;
    width: 100%;
    text-align: center;
}

.mobile-menu ul li {
    margin: 25px 0;
    opacity: 0;
    transform: translateY(20px);
    transition: all 0.3s ease;
}

.mobile-menu.active ul li {
    opacity: 1;
    transform: translateY(0);
}

.mobile-menu ul li a {
    color: white;
    text-decoration: none;
    font-size: 1.4rem;
    padding: 12px 20px;
    display: inline-block;
    transition: all 0.3s ease;
    text-transform: uppercase;
    letter-spacing: 1px;
    position: relative;
}

.mobile-menu ul li a::after {
    content: '';
    position: absolute;
    width: 0;
    height: 2px;
    bottom: 0;
    left: 50%;
    background: #00ffff;
    transition: all 0.3s ease;
    transform: translateX(-50%);
}

.mobile-menu ul li a:hover::after {
    width: 100%;
}

.mobile-menu ul li a:hover {
    color: #00ffff;
    text-shadow: 0 0 10px rgba(0, 255, 255, 0.5);
}

.mobile-language-selector {
    margin-top: 40px;
    display: flex;
    justify-content: center;
    gap: 15px;
}

.mobile-language-selector button {
    background: none;
    border: 2px solid rgba(0, 255, 255, 0.3);
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1.1rem;
    transition: all 0.3s ease;
    text-transform: uppercase;
    min-width: 60px;
}

.mobile-language-selector button:hover {
    background: rgba(0, 255, 255, 0.1);
    border-color: #00ffff;
    box-shadow: 0 0 15px rgba(0, 255, 255, 0.3);
}

@media screen and (max-width: 768px) {
    .nav-items,
    .language-selector {
        display: none;
    }

    .mobile-menu-button {
        display: flex;
    }

    .navbar {
        padding: 15px 0;
        position: relative;
    }

    .navbar-container {
        justify-content: center;
        padding: 0 60px;
    }

    .logo-container {
        margin: 0;
    }

    .company-logo {
        height: 50px;
    }

    .mobile-menu ul li {
        animation: fadeInUp 0.5s ease forwards;
        animation-delay: calc(0.2s * var(--i));
    }
}

@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

/* RTL desteği */
[dir="rtl"] .mobile-menu {
    left: auto;
    right: -100%;
}

[dir="rtl"] .mobile-menu.active {
    right: 0;
}

/* Mobil cihazlar için özel ayarlar */
@media screen and (max-width: 480px) {
    .mobile-menu {
        padding: 60px 15px;
    }

    .mobile-menu ul li {
        margin: 15px 0;
    }

    .mobile-menu ul li a {
        font-size: 1.1rem;
        padding: 8px 16px;
    }

    .mobile-language-selector {
        margin-top: 25px;
        gap: 10px;
    }

    .mobile-language-selector button {
        padding: 8px 14px;
        font-size: 0.9rem;
        min-width: 50px;
        border-width: 1px;
    }
}

/* Çok küçük ekranlar için */
@media screen and (max-height: 600px) {
    .mobile-menu {
        padding-top: 40px;
    }

    .mobile-menu ul li {
        margin: 10px 0;
    }

    .mobile-menu ul li a {
        font-size: 1rem;
        padding: 6px 12px;
    }

    .mobile-language-selector {
        margin-top: 15px;
    }
} 