.video-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    overflow: hidden;
}

.video-background video {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.video-background::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
}

.main-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 80vh;
    text-align: center;
    padding: 20px;
    color: white;
    position: relative;
    z-index: 1;
}

.neon-title {
    font-size: 4.5rem;
    margin-bottom: 20px;
    line-height: 1.2;
}

.neoplus-text {
    color: #00ffff;
    text-shadow: 0 0 10px rgba(0, 255, 255, 0.7),
                 0 0 20px rgba(0, 255, 255, 0.5),
                 0 0 30px rgba(0, 255, 255, 0.3);
    font-weight: bold;
    letter-spacing: 2px;
}

.subtitle {
    font-size: 2.5rem;
    color: white;
    margin: 20px 0;
    font-weight: 300;
}

.large-text {
    font-size: 3.8rem;
    color: white;
    margin: 30px 0;
    line-height: 1.2;
    font-weight: 600;
    text-shadow: 0 0 15px rgba(255, 255, 255, 0.3);
}

.hero-description {
    max-width: 800px;
    font-size: 1.3rem;
    line-height: 1.8;
    margin: 30px auto;
    color: rgba(255, 255, 255, 0.9);
}

.gradient-button {
    background: linear-gradient(90deg, #00ffff, #ff00ff);
    border: none;
    color: white;
    padding: 18px 36px;
    font-size: 1.2rem;
    border-radius: 30px;
    cursor: pointer;
    transition: all 0.3s ease;
    position: relative;
    overflow: hidden;
    margin-top: 30px;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 1px;
}

.gradient-button::before {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.2), transparent);
    transition: all 0.5s ease;
}

.gradient-button:hover {
    transform: scale(1.05);
    box-shadow: 0 0 20px rgba(0, 255, 255, 0.5);
}

.gradient-button:hover::before {
    left: 100%;
}

/* Dark mode styles */
.dark-mode {
    color: white;
}

.dark-mode .navbar {
    background: rgba(0, 0, 0, 0.6);
    border-bottom: 1px solid rgba(0, 255, 255, 0.1);
}

.dark-mode .about-container,
.dark-mode .services-section,
.dark-mode .contact-section {
    background: rgba(0, 0, 0, 0.6);
}

/* Responsive Tasarım */
@media screen and (max-width: 1200px) {
    .neon-title {
        font-size: 3.5rem;
    }

    .large-text {
        font-size: 3rem;
    }

    .description {
        max-width: 600px;
    }
}

@media screen and (max-width: 768px) {
    .neon-title {
        font-size: 3rem;
    }

    .subtitle {
        font-size: 2rem;
    }

    .large-text {
        font-size: 2.8rem;
    }

    .hero-description {
        font-size: 1.1rem;
        padding: 0 20px;
    }

    .gradient-button {
        padding: 15px 30px;
        font-size: 1.1rem;
    }

    .main-content {
        min-height: 70vh;
        padding: 15px;
    }

    .content-box {
        padding: 30px 20px;
    }
}

@media screen and (max-width: 480px) {
    .neon-title {
        font-size: 2.5rem;
    }

    .subtitle {
        font-size: 1.8rem;
    }

    .large-text {
        font-size: 2.2rem;
    }

    .hero-description {
        font-size: 1rem;
        line-height: 1.6;
    }

    .gradient-button {
        padding: 12px 24px;
        font-size: 1rem;
    }

    .main-content {
        padding: 10px;
    }

    .content-box {
        padding: 30px 20px;
        width: 95%;
    }
}

/* Yükseklik için özel media query */
@media screen and (max-height: 600px) {
    .main-content {
        min-height: auto;
        padding-top: 60px;
    }

    .description {
        margin: 15px 0;
    }
}

/* Yeni eklenen box stili */
.content-box {
    background: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(10px);
    border-radius: 20px;
    padding: 50px 40px;
    box-shadow: 0 0 30px rgba(0, 255, 255, 0.2),
                inset 0 0 30px rgba(0, 255, 255, 0.1);
    border: 1px solid rgba(0, 255, 255, 0.1);
    max-width: 900px;
    width: 90%;
    margin: 0 auto;
    text-align: center;
    animation: glowPulse 3s infinite;
}

@keyframes glowPulse {
    0% {
        box-shadow: 0 0 20px rgba(0, 255, 255, 0.2),
                    inset 0 0 20px rgba(0, 255, 255, 0.1);
    }
    50% {
        box-shadow: 0 0 30px rgba(0, 255, 255, 0.3),
                    inset 0 0 30px rgba(0, 255, 255, 0.2);
    }
    100% {
        box-shadow: 0 0 20px rgba(0, 255, 255, 0.2),
                    inset 0 0 20px rgba(0, 255, 255, 0.1);
    }
}

.testimonials-section {
    padding: 40px 0;
    background: rgba(0, 0, 0, 0.8);
    min-height: 100vh;
    scroll-margin-top: 80px;
}

.contact-section {
    padding: 40px 0;
    background: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(10px);
} 