.services-section {
    padding: 60px 20px;
    background: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(10px);
}

.services-container {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.services-title {
    text-align: center;
    font-size: clamp(2rem, 5vw, 3.5rem);
    margin-bottom: 50px;
    color: #00ffff;
    text-shadow: 0 0 20px rgba(0, 255, 255, 0.5);
}

.services-title::after {
    content: '';
    position: absolute;
    bottom: -15px;
    left: 50%;
    transform: translateX(-50%);
    width: 100px;
    height: 2px;
    background: linear-gradient(90deg, #00ffff, #ff00ff);
}

.services-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: clamp(20px, 3vw, 40px);
    max-width: 1400px;
    margin: 0 auto;
    padding: 0 20px;
}

.card {
    width: 100%;
    max-width: 350px;
    height: 450px;
    background: #07182E;
    position: relative;
    display: flex;
    place-content: center;
    place-items: center;
    overflow: hidden;
    border-radius: 20px;
    margin: 0 auto;
}

.card h2 {
    z-index: 1;
    color: white;
    font-size: clamp(1.5em, 3vw, 2em);
}

.card::before {
    content: '';
    position: absolute;
    width: 100px;
    background-image: linear-gradient(180deg, rgb(0, 183, 255), rgb(255, 48, 255));
    height: 130%;
    animation: rotBGimg 3s linear infinite;
    transition: all 0.2s linear;
}

@keyframes rotBGimg {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.card::after {
    content: '';
    position: absolute;
    background: #07182E;
    inset: 5px;
    border-radius: 15px;
}

.service-content {
    position: relative;
    z-index: 2;
    padding: clamp(20px, 4vw, 30px);
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.service-title {
    font-size: clamp(1.8rem, 3vw, 2.2rem);
    font-weight: bold;
    color: #00ffff;
    margin-bottom: clamp(15px, 2vw, 20px);
    text-shadow: 0 0 10px rgba(0, 255, 255, 0.3);
}

.service-description {
    font-size: clamp(1rem, 2vw, 1.2rem);
    color: white;
    line-height: 1.6;
    max-width: 90%;
    margin: 0 auto;
}

.service-icon {
    margin-bottom: clamp(15px, 2vw, 20px);
    color: #00ffff;
}

.service-icon svg {
    width: clamp(40px, 5vw, 60px);
    height: clamp(40px, 5vw, 60px);
}

/* Ekran boyutlarına göre özel ayarlamalar */
@media screen and (max-width: 1400px) {
    .services-grid {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media screen and (max-width: 1200px) {
    .services-grid {
        grid-template-columns: repeat(2, 1fr);
    }
    
    .card {
        max-width: 320px;
        height: 420px;
    }
}

@media screen and (max-width: 768px) {
    .services-section {
        padding: 40px 15px;
    }
    
    .services-grid {
        grid-template-columns: 1fr;
        gap: 30px;
    }
    
    .card {
        max-width: 300px;
        height: 400px;
    }
}

@media screen and (max-width: 480px) {
    .services-section {
        padding: 30px 10px;
    }
    
    .card {
        max-width: 280px;
        height: 380px;
    }
    
    .service-content {
        padding: 15px;
    }
}

/* Tablet ve mobil cihazlarda hover efektlerini devre dışı bırak */
@media (hover: none) {
    .card::before {
        animation: none;
    }
}

/* Smooth scroll */
html {
    scroll-behavior: smooth;
} 