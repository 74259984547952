.contact-section {
    padding: 80px 0;
    background: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(10px);
}

.contact-title {
    text-align: center;
    color: #00ffff;
    font-size: 2.5rem;
    margin-bottom: 50px;
    text-shadow: 0 0 10px rgba(0, 255, 255, 0.5);
}

.contact-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
}

.contact-content {
    display: flex;
    gap: 50px;
}

.contact-info {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.info-item {
    display: flex;
    align-items: flex-start;
    gap: 20px;
    padding: 30px;
    background: rgba(7, 24, 34, 0.6);
    border: 1px solid rgba(0, 255, 255, 0.3);
    border-radius: 10px;
    transition: all 0.3s ease;
}

.info-item:hover {
    transform: translateY(-5px);
    box-shadow: 0 5px 15px rgba(0, 255, 255, 0.2);
    border-color: rgba(0, 255, 255, 0.5);
}

.icon-wrapper {
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 255, 255, 0.1);
    border-radius: 50%;
    color: #00ffff;
}

.info-content h3 {
    color: #00ffff;
    margin: 0 0 10px 0;
    font-size: 1.2rem;
}

.info-content p {
    color: white;
    margin: 0;
    font-size: 1rem;
    line-height: 1.5;
}

.contact-map {
    flex: 1;
    min-height: 400px;
}

.map-container {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    overflow: hidden;
    border: 1px solid rgba(0, 255, 255, 0.3);
}

/* Responsive tasarım */
@media screen and (max-width: 1024px) {
    .contact-content {
        flex-direction: column;
    }

    .contact-map {
        min-height: 300px;
    }
}

@media screen and (max-width: 768px) {
    .contact-title {
        font-size: 2rem;
    }

    .info-item {
        padding: 20px;
    }

    .icon-wrapper {
        width: 40px;
        height: 40px;
    }

    .info-content h3 {
        font-size: 1.1rem;
    }

    .info-content p {
        font-size: 0.9rem;
    }
}

@media screen and (max-width: 480px) {
    .contact-section {
        padding: 60px 0;
    }

    .contact-title {
        font-size: 1.8rem;
        margin-bottom: 30px;
    }

    .contact-content {
        gap: 30px;
    }

    .info-item {
        padding: 15px;
    }
} 