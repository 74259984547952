.about-section {
    min-height: 100vh;
    background: rgba(0, 0, 0, 0.8);
    padding: 80px 20px;
}

.about-container {
    padding: 80px 20px;
    background: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(10px);
}

.about-title {
    text-align: center;
    font-size: 3.5rem;
    margin-bottom: 60px;
    position: relative;
    display: inline-block;
    background: linear-gradient(90deg, #00ffff, #ff00ff);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-shadow: 0 0 20px rgba(0, 255, 255, 0.5);
    width: 100%;
}

.about-title::before {
    content: '';
    position: absolute;
    width: 100px;
    height: 2px;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
    background: linear-gradient(90deg, #00ffff, #ff00ff);
}

.about-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 30px;
    max-width: 1200px;
    margin: 0 auto;
}

.about-card {
    background: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(10px);
    border-radius: 15px;
    padding: 30px;
    color: white;
    transition: all 0.3s ease;
    border: 1px solid rgba(0, 255, 255, 0.1);
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 350px;
}

.about-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 0 20px rgba(0, 255, 255, 0.2);
}

.card-icon {
    font-size: 2.5rem;
    margin-bottom: 25px;
    background: rgba(0, 255, 255, 0.1);
    padding: 20px;
    border-radius: 50%;
    box-shadow: 0 0 15px rgba(0, 255, 255, 0.2);
}

.about-card h3 {
    font-size: 1.8rem;
    margin: 20px 0;
    color: #00ffff;
    text-shadow: 0 0 10px rgba(0, 255, 255, 0.3);
    font-weight: 600;
    position: relative;
}

.about-card h3::after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
    width: 50px;
    height: 2px;
    background: linear-gradient(90deg, #00ffff, #ff00ff);
}

.about-card p {
    line-height: 1.8;
    opacity: 0.9;
    font-size: 1.1rem;
    margin-top: 20px;
    padding: 0 15px;
}

.neoplus-text {
    color: #00ffff;
    text-shadow: 0 0 10px rgba(0, 255, 255, 0.7),
                 0 0 20px rgba(0, 255, 255, 0.5),
                 0 0 30px rgba(0, 255, 255, 0.3);
    font-weight: bold;
}

/* Responsive tasarım */
@media screen and (max-width: 768px) {
    .about-section {
        padding: 60px 15px;
    }

    .about-title {
        font-size: 2.8rem;
    }

    .about-grid {
        grid-template-columns: 1fr;
        gap: 20px;
    }

    .about-card {
        padding: 20px;
    }
}

@media screen and (max-width: 480px) {
    .about-title {
        font-size: 2.2rem;
        margin-bottom: 50px;
    }
} 